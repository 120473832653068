import * as React from "react"
import {graphql} from "gatsby"
import {Link} from "gatsby"
import Footer from "../components/footer";

const IndexPage = ({data}) => {
  return (
      <>
        <main>
          <title>Bestill prøvekjøring av bil | Alle merker</title>

          <h1>Bestill prøvekjøring av bil</h1>
          <p>Book prøvekjøring for følgende bilmerker:</p>
          <ul>
            {data.allSanityCarBrand.edges.map(({node},i) => {
              return(
                  <li key={i}><Link to={'/'+node.slug.current}>{node.brand}</Link></li>
              )
            })}
          </ul>
        </main>
        <Footer/>
      </>
  )
}

export default IndexPage


export const query = graphql`
query CarQuery {
      allSanityCarBrand(filter: {slug: {current: {ne: null}}}, , sort: {order: ASC, fields: slug___current}) {
        edges {
          node{
            slug{
                current
            }
            brand
            intro
            image {
              asset {
                url
              }
            }
          }
        }
      }
    }
`